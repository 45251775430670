<template>
  <div class="login">
    <div id="fb-root"></div>
    <img v-if="isStatus" :src="picture" />
    <h3 v-if="isStatus">Welcome To {{ name }}</h3>
    <div class="d-grid gap-2 d-md-block">
      <button
        v-if="!isStatus"
        style="background-color: #1771ed"
        class="btn btn-facebook"
        @click="loginWithFacebook"
      >
        <i class="fab fa-facebook fa-lg"></i>
        Login with Facebook
      </button>
      <button v-if="!isStatus" class="btn btn-google" @click="login()">
        <i class="fab fa-google fa-lg"></i> Login with Google
      </button>
      <button v-if="!isStatus" class="btn btn-line" @click="loginline()">
        <i class="fab fa-line fa-lg"></i> Login with Line
      </button>
    </div>
    <button class="btn btn-danger" v-if="isLoginGoogle" @click="logOut">
      Logout</button
    ><br />
    <button
      class="btn btn-danger"
      v-if="isLoginFacebook"
      @click="logoutWithFacebook"
    >
      Logout
    </button>
  </div>
</template>

<script>
import { initFbsdk } from "../config/fb.js";
import queryString from "querystring";
import axios from "axios";
import OAuthRequest from "../Entities/OAuthRequest";
import linq from "linq";
import OAuthResult from "../Entities/OAuthResult";

export default {
  name: "Login",
  components: {},

  data() {
    return {
      isStatus: false,
      isLoginFacebook: false,
      isLoginGoogle: false,
      name: "",
      personalID: "",
      email: "",
      picture: "",
      googleSignInParams: {
        clientId:
          "980391602017-jimh5r8mmqbmiaoffs8o7rsbsojie95f.apps.googleusercontent.com",
      },
      clientId: "1656909287",
      clientSecret: "d116c30e77756b5e8ba408357064e014",
      callbackUri: "https://uncle-naj-garden.ponnipa.in.th/",
    };
  },
  methods: {
    checkLoginState() {
      FB.getLoginStatus(function (response) {
        console.log(response);
        if (response.status == "connected") {
          this.isLoginFacebook = true;
        }
      });
    },
    loginWithFacebook() {
      this.checkLoginState();
      FB.login(
        (response) => {
          if (response.status == "connected") {
            this.getUserData();
            console.log(response.authResponse.accessToken);
            console.log(response.authResponse.graphDomain);
          }
        },
        { scope: "email,public_profile" }
      );
    },
    getUserData() {
      FB.api("/me?fields=name,email,picture", function (response) {
        if (response) {
          console.log(response);
          this.personalID = response.id;
          this.email = response.email;
          this.name = response.name;
          this.picture = response.picture.data.url;
          this.isStatus = true;
          console.log(this.isStatus);
        }
      });
    },
    logoutWithFacebook() {
      this.isLogin = false;
    },
    onSignInSuccess(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      const profile = googleUser.getBasicProfile(); // etc etc
      console.log(profile);
    },
    async logOut() {
      const result = await this.$gAuth.signOut();
      this.isLogin = false;
      console.log(`result`, result);
      this.isLoginGoogle = false;
      this.isStatus = false;
    },
    async login() {
      const googleUser = await this.$gAuth.signIn();
      console.log("googleUser", googleUser);
      console.log("getId", googleUser.getId());

      console.log("getBaseProfile", googleUser.getBasicProfile());
      console.log("getAuthResponse", googleUser.getAuthResponse());
      console.log(
        "getAuthResponse$G",
        this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      );
      this.isLoginGoogle = this.$gAuth.isAuthorized;
      this.name = googleUser.getBasicProfile().tf;
      this.picture = googleUser.getBasicProfile().AN;
      this.email = googleUser.getBasicProfile().zv;
      this.personalID = googleUser.getBasicProfile().SW;
      this.isStatus = true;
    },
    async created(code) {
      if (!code) return;
      const result = await this.getToken(code);
      const { data } = await this.getProfile(result.token);
      console.log(data);
      if (this.friendRequired) {
        const flag = await this.checkFriend(result.token);
        if (!flag) {
          this.error = this.friendErrorTest;
        }
      }
      if (!this.error) {
        const response = Object.assign(data, result.getPostable());
        this.$emit("result", response);
      }
    },
    loginline() {
      const url = "https://access.line.me/oauth2/v2.1/authorize";
      let params = {
        response_type: "code",
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: "openid profile",
      };
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: "consent",
          bot_prompt: "aggressive",
        });
      }
      window.location.href = `${url}?${queryString.stringify(params)}`;
    },
    async getToken(code) {
      const result = new OAuthRequest({
        code: code,
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        redirectUri: this.callbackUri,
      });
      const params = new URLSearchParams();
      linq
        .from(result.getPostable())
        .select((x) => params.append(x.key, x.value))
        .toArray();
      const { data } = await axios.post(
        "https://api.line.me/oauth2/v2.1/token",
        params
      );
      return new OAuthResult(data);
    },
    async getProfile(token) {
      return await axios.get("https://api.line.me/v2/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    async checkFriend(token) {
      const { data } = await axios.get(
        "https://api.line.me/friendship/v1/status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.friendFlag;
    },
  },
  mounted() {
    initFbsdk();
              const { code } = queryString.parse(window.location.search.replace("?", ""));
this.getToken(code)

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn-facebook {
  background: #3b5998;
  color: #fff;
}
a:hover {
  color: #fff;
  opacity: 0.8;
}
.btn-google {
  background: #dc483c;
  color: #fff;
}
.btn-line {
  background: #00b900;
  color: #fff;
}
</style>
